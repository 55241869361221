






















































































































































import ADAAttendanceAnalyticsComponent from '@/components/analyticsComponent/scholarAnalyticsComponent/scholarAttendanceAnalyticsComponent/adaAttendanceAnalyticsComponent/ADAAttendanceAnalyticsComponent';
export default ADAAttendanceAnalyticsComponent;
