import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { IAdaWeekLinePayload } from '@/Model/model';

import APP_CONST from '@/constants/AppConst';
import APP_UTILITIES from '@/utilities/commonFunctions';
import BarChart from '@/chartcomponents/barcomponent/BarComponent.vue';
import BouncingPreloaderComponent from '@/commoncomponents/bouncingpreloadercomponent/BouncingPreloaderComponent.vue';
import DropdownList from '@/ui-components/dropdownListBx/DropdownList.vue';
import LineChart from '@/chartcomponents/linecomponent/LineChart.vue';
import MultiSelectDropdown from '@/ui-components/multiSelectDropdown/MultiSelectDropdown.vue';
import analyticsStore from '@/store/modules/analyticsStore';

@Component({
  components: {
    'multi-select-dropdown': MultiSelectDropdown,
    'dropdown': DropdownList,
    'line-chart': LineChart,
    'BarChart': BarChart,
    'bouncing-preloader': BouncingPreloaderComponent,
  }
})


export default class ADAAttendanceAnalyticsComponent extends Vue {
  public masterTabsValue = APP_CONST.DAY_WEEK_TABS;
  public responseReceived: boolean = false;
  public adaResponseReceived: boolean = false;
  public singleSiteDropdown = { label: '', id: 0, value: 'Select Session', firstSelectLabel: 'Select Session', required: false, disable: false, search: true, placeholder: 'Search', showSelectLabel: true, dropdownList: [] as { id: number; value: string }[] };
  public selectedTab: string = 'Week';
  public multiSiteDropdown = APP_CONST.ATTENDANCE_ADA_Groups_CONFIG;
  public yScaleLabel: string = '(%) Percent';
  public yScaleLabelBar: string = 'Percent (%)';
  public applicationData: any = {
    labels: [],
    datasets: []
  };
  public isNoSubmissions: boolean = false;
  public xAxisLabelString: string = '';
  public isDisplayXLabel: boolean = false;
  public barChartBackground: string = APP_CONST.STYLE.COLOR.BLUE_700;
  public barChartHoverBackground: string = APP_CONST.STYLE.COLOR.BLUE_700;
  public highestRole: number = JSON.parse(JSON.stringify(APP_UTILITIES.getCookie('highest_role')));
  public localConst: any = APP_CONST;
  public isMobileView: boolean = false;
  public emptyBarStateStyle: any = {};
  public isChartMount: boolean = false;
  public isStaffNoGroupSelected: boolean = false;
  public isLineChartLoaderVisible: boolean = false;
  public isBarChartLoaderVisible: boolean = false;

  get isGroupsDisabled() {
    const isDisable = this.selectedSiteGroups.length
      ? false
      : true;
    return isDisable;
  }


  /* istanbul ignore next */
  get selectedAttendanceAnalyticsTab() {
    return analyticsStore.selectedAttendanceAnalyticsTabValue;
  }

  get selectedProgramData() {
    return analyticsStore.slectedProgram;
  }

  get sitesDropdown() {
    return analyticsStore.selectedprogramSites;
  }

  get selectedSiteGroups() {
    return analyticsStore.selectedSiteGroups;
  }

  get adaWeekLineData() {
    return analyticsStore.adaWeekLineData;
  }

  get showLegends() {
    let checkedGroups: boolean = false;
    checkedGroups = this.adaWeekLineData && this.adaWeekLineData.datasets && this.adaWeekLineData.datasets[0] && this.adaWeekLineData.datasets[0].label
      ? true
      : false;
    return checkedGroups;
  }

  get adaBarGraphData() {
    return analyticsStore.adaBarGraphData;
  }

  get adaProgramPercentage() {
    return analyticsStore.adaProgramPercentage;
  }

  get selectedSite() {
    return analyticsStore.adaselectedSite;
  }

  @Prop()
  selectedProgram!: any;

  beforeMount() {
    this.isBarChartLoaderVisible = true;
    this.isLineChartLoaderVisible = true;
    analyticsStore.disableAdaProgramDropdown(true);
    this.adaResponseReceived = false;
    this.isMobileView = APP_UTILITIES.isMobile();
    analyticsStore.mutateAnalyticsAttendanceTabSwitch('ADA');
    this.isChartMount = true;
    if (APP_UTILITIES.getFilteredColumns('attendanceselectedprogram')) {
      const program: any = APP_UTILITIES.getFilteredColumns('attendanceselectedprogram');
      const selectedProgram = JSON.parse(program);
      analyticsStore.mutateSelectedProgramName(selectedProgram);
      analyticsStore.mutateSelectedGroups([]);
      analyticsStore.mutateSelectedSite({ id: 0, value: '' });
      analyticsStore.adaCsvEnableDisableItems();
      this.getProgramSites();
      analyticsStore.getAdaProgramData(selectedProgram.programId).then(() => {
        this.isBarChartLoaderVisible = false;
        if (!this.isBarChartLoaderVisible && !this.isLineChartLoaderVisible) {
          analyticsStore.disableAdaProgramDropdown(false);
        }
        this.adaResponseReceived = true;
      });
      analyticsStore.getPersistenceScholarTab({ selectedTab: 'attendanceAda', programId: selectedProgram.programId }).then(() => {
        this.prepareGraphData();
      });
    }
    else {
      analyticsStore.programNameSelection().then(() => {
        analyticsStore.mutateSelectedGroups([]);
        analyticsStore.mutateSelectedSite({ id: 0, value: '' });
        analyticsStore.adaCsvEnableDisableItems();
        this.getProgramSites();
        analyticsStore.getAdaProgramData(this.selectedProgramData.programId).then(() => {
          this.isBarChartLoaderVisible = false;
          if (!this.isBarChartLoaderVisible && !this.isLineChartLoaderVisible) {
            analyticsStore.disableAdaProgramDropdown(false);
          }
          this.adaResponseReceived = true;
        });
        analyticsStore.getPersistenceScholarTab({ selectedTab: 'attendanceAda', programId: this.selectedProgramData.programId }).then(() => {
          this.prepareGraphData();
        });
      });
    }

    if (APP_UTILITIES.getFilteredColumns('adaTabSelection')) {
      let storedTab: any = APP_UTILITIES.getFilteredColumns('adaTabSelection');
      storedTab = JSON.parse(storedTab);
      this.selectedTab = storedTab.name;
    }
    else {
      this.selectedTab = 'Week';
    }

  }

  updated() {
    this.isMobileView = APP_UTILITIES.isMobile();
  }

  @Watch('selectedProgramData', { deep: true })
  onProgramSelection(val: any) {
    if (!this.isChartMount) {
      this.isBarChartLoaderVisible = true;
      this.isLineChartLoaderVisible = true;
      analyticsStore.disableAdaProgramDropdown(true);
      this.adaResponseReceived = false;
      analyticsStore.mutateSelectedGroups([]);
      this.getProgramSites();
      analyticsStore.getAdaProgramData(val.programId).then(() => {
        this.isBarChartLoaderVisible = false;
        if (!this.isBarChartLoaderVisible && !this.isLineChartLoaderVisible) {
          analyticsStore.disableAdaProgramDropdown(false);
        }
        this.adaResponseReceived = true;
      });

      analyticsStore.getPersistenceScholarTab({ selectedTab: 'attendanceAda', programId: val.programId }).then(() => {
        this.prepareGraphData();
      });
    }
    this.isChartMount = false;
  }

  getProgramSites() {
    if (this.selectedProgramData && this.selectedProgramData.programId) {
      analyticsStore.getProgramAttendenceCompletionSites(this.selectedProgramData.programId);
    }
  }

  tabSwitch(tab: { name: string }) {
    this.selectedTab = tab.name;
    APP_UTILITIES.setFilteredColumns('adaTabSelection', tab);
    setTimeout(() => {
      this.prepareGraphData();
    }, 50);

  }

  singleSiteSelection(site: any) {
    this.isLineChartLoaderVisible = true;
    analyticsStore.disableAdaProgramDropdown(true);
    setTimeout(() => {
      analyticsStore.mutateSelectedSite(site);
      analyticsStore.mutateSelectedGroups([]);
      analyticsStore.adaCsvEnableDisableItems();
      const payload = {
        siteId: this.selectedSite.id,
        programId: this.selectedProgramData.programId
      };
      if (this.selectedSite.id == 0) {
        analyticsStore.mutateSelectedGroups([]);
      }
      analyticsStore.getGroupsBySiteid(payload).then((res: any) => {
        this.prepareGraphData();
        analyticsStore.mutateAttendanceSiteSession({ programId: this.selectedProgramData.programId, site: site, groups: this.selectedSiteGroups });
      });

    }, 1000);
  }

  selectGroup(group: any) {
    if (group.selectedValue != undefined) {
      analyticsStore.mutateSelectedGroups([]);
      analyticsStore.mutateSelectedGroups(group.dropdownList);
      analyticsStore.mutateAttendanceSiteSession({ programId: this.selectedProgramData.programId, site: this.selectedSite, groups: group.dropdownList });
    }
  }


  prepareGraphData() {
    this.isLineChartLoaderVisible = true;
    analyticsStore.disableAdaProgramDropdown(true);
    const payload: IAdaWeekLinePayload = {
      programId: this.selectedProgramData.programId,
      siteId: this.selectedSite.id,
      groupIds: [],
      isDay: this.selectedTab == 'Day'
        ? true
        : false
    };

    if (this.selectedSiteGroups && this.selectedSiteGroups.length) {
      this.selectedSiteGroups.forEach((group: { id: any; checked: boolean }) => {
        if (group.checked) {
          payload.groupIds.push(group.id);
        }
      });
    }
    this.responseReceived = false;
    analyticsStore.getAdaWeekLineData(payload).then((response: any) => {
      this.isChartMount = false;
      this.responseReceived = true;
      this.isLineChartLoaderVisible = false;
      if (!this.isBarChartLoaderVisible && !this.isLineChartLoaderVisible) {
        analyticsStore.disableAdaProgramDropdown(false);
      }
      analyticsStore.mutateAdaWeekGraphDAta(response);
      this.scrollReset();
    });
  }

  scrollReset() {
    const elem: any = document.getElementById('line-chart-ada-week') as HTMLElement;
    elem && elem.scrollTo(0, 0);
  }

  get lineChartStyle() {
    let adaWeekLength: number = 0;
    if (this.selectedTab == 'Week') {
      adaWeekLength = this.adaWeekLineData && this.adaWeekLineData.labels && this.adaWeekLineData.labels.length
        ? this.adaWeekLineData.labels.length * (this.adaWeekLineData.labels.length * 10 > 110
          ? 110
          : this.adaWeekLineData.labels.length * 10)
        : 0;
    }
    else {
      adaWeekLength = this.adaWeekLineData && this.adaWeekLineData.labels && this.adaWeekLineData.labels.length
        ? this.adaWeekLineData.labels.length * (this.adaWeekLineData.labels.length * 5 > 50
          ? 50
          : this.adaWeekLineData.labels.length * 5)
        : 0;
    }
    const styles: any = {
      width: adaWeekLength > 1280
        ? `${adaWeekLength}px`
        : '100%',
      height: '400px',
      position: 'relative'
    };
    return styles;
  }

  get emtyStateMessage() {
    let emptyStateMessage = APP_CONST.BLANK;
    this.isStaffNoGroupSelected = false;
    if (this.highestRole == APP_CONST.ROLE_TYPE_STAFF && this.selectedSite && this.selectedSiteGroups) {
      if ((this.selectedSiteGroups && this.selectedSiteGroups.length && Object.keys(this.selectedSiteGroups).every((ele: any) => !this.selectedSiteGroups[ele].checked)) || (this.selectedSiteGroups && !this.selectedSiteGroups.length)) {
        this.isStaffNoGroupSelected = true;
      }
    }
    this.isNoSubmissions = this.adaWeekLineData && this.adaWeekLineData.datasets && Object.keys(this.adaWeekLineData.datasets).every((element: any) => this.adaWeekLineData.datasets[element].data && !this.adaWeekLineData.datasets[element].data.length);

    if (this.selectedSite.value == '' || !this.sitesDropdown.length || this.selectedSite.id == 0) {
      emptyStateMessage = APP_CONST.NO_SITE_SESSION;
    }
    else if (this.isNoSubmissions && !this.isStaffNoGroupSelected) {
      emptyStateMessage = APP_CONST.AWAITING_ATTENDANCE_INPUT;
    }

    return emptyStateMessage;
  }

  get barChartStyle() {
    let allApplicationLength: number = 0;
    if (this.isMobileView) {
      allApplicationLength = (this.adaBarGraphData && this.adaBarGraphData.length)
        ? this.adaBarGraphData.length * (this.adaBarGraphData.length * 50 > 110
          ? 110
          : this.adaBarGraphData.length * 50)
        : 0;
    }
    else {
      allApplicationLength = (this.adaBarGraphData && this.adaBarGraphData.length)
        ? this.adaBarGraphData.length * (this.adaBarGraphData.length * 10 > 110
          ? 110
          : this.adaBarGraphData.length * 10)
        : 0;
    }
    const styles: any = {
      width: allApplicationLength > document.documentElement.clientWidth
        ? `${allApplicationLength}px`
        : '100%',
      height: '400px',
      position: 'relative'
    };
    return styles;
  }

  get barEmtyStateMessage() {
    let emptyStateMessage = APP_CONST.BLANK;
    this.emptyBarStateStyle = {};
    if (!this.sitesDropdown.length) {
      emptyStateMessage = APP_CONST.NO_SITE_SESSION_DATA;
      if (this.isMobileView) {
        this.emptyBarStateStyle = {
          bottom: '150px',
          width: '190px',
        };
      }
      else {
        this.emptyBarStateStyle = {
          bottom: '150px',
          width: '291px',
        };
      }
    }
    else if (this.adaProgramPercentage == '--') {
      emptyStateMessage = APP_CONST.AWAITING_ATTENDANCE_INPUT;
      if (this.isMobileView) {
        this.emptyBarStateStyle = {
          bottom: '240px',
          width: '190px',
        };
      }
      else {
        this.emptyBarStateStyle = {
          bottom: '240px',
          width: '291px',
        };
      }

    }
    return emptyStateMessage;
  }

}
